import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { Container } from 'reactstrap';


const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container>
      <div className="d-flex justify-content-center align-items-center py-5">
        <div className="text-center py-2">
          <h1>Page not found</h1>
        </div>
      </div>
    </Container>
  </Layout>
)

export default NotFoundPage
